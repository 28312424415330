<template>
  <div>
    <v-container fluid class="pb-12" :class="isMobileScreen ? 'pa-0' : ''"> 
      <v-data-table
        v-model="selected"
        show-select
        :headers="headers"
        :items="getPromotionList"
        :single-select="singleSelect"
        sort-by="name"
        :search="search"
        item-key="id"
        :locale="$i18n.locale"
        :loading="isLoading"
        :no-results-text="$t('NORESULTS')"
        class="mb-16" 
        :class="!isMobileScreen ? ' elevation-1' : ' elevation-0'">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              <h1 class="title font-weight-regular">
                <v-icon color="primary" class="mb-1 mr-2">mdi-tag</v-icon>
                {{ $t('PROMOTION') }} 
              </h1>
            </v-toolbar-title>

            <v-divider
              class="mx-4 d-none d-sm-flex"
              inset
              vertical>
            </v-divider>

            <v-spacer v-show="!isMobileScreen"></v-spacer>
            
            <v-text-field
              class="ml-4"
              clearable
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('SEARCH')"
              single-line
              hide-details>
            </v-text-field>

            <v-spacer v-show="!isMobileScreen"></v-spacer>

            <v-btn
              class="mx-4 d-none d-sm-flex"
              v-if="isSelected"
              color="primary"
              text>
              {{ $t('promotags.BACKTODRAFT') }}
              <v-icon right>mdi-autorenew
              </v-icon>
            </v-btn>

            <v-btn
              class="mx-4 d-none d-sm-flex"
              v-if="isSelected"
              color="primary"
              text>
              {{ $t('location.SENDTOTRASH') }}
              <v-icon right>mdi-delete
              </v-icon>
            </v-btn>

            <!-- Formulario Nuevo y editar publicación -->
            <v-dialog 
              v-model="dialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition">

              <template v-slot:activator="{ on, attrs }">
                <transition name="scale-transition">
                  <v-btn
                    color="primary"
                    dark
                    class="ml-2 d-none d-md-flex"
                    v-bind="attrs"
                    v-on="on">
                    {{ $tc('promotags.NEW', 1) }}
                    <v-icon right dark>mdi-note-plus
                    </v-icon>
                  </v-btn>
                </transition>
              </template>

              <v-toolbar class="custom-toolbar" absolute tile flat color="primary">
                <v-toolbar-title>
                  <h1 class="title font-weight-regular white--text">
                    <v-icon dark>{{ formIcon }}</v-icon>
                    {{ $tc(formTitle, 1) }} 
                  </h1>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  dark
                  text
                  :disabled="!promotionStore.gotChange"
                  @click="save"
                  class="mr-4">
                  {{ $t('SAVE') }}
                  <v-icon right>mdi-content-save
                  </v-icon>
                </v-btn>
                <v-btn 
                  color="secondary" 
                  tile class="rounded-l-xl d-none d-sm-flex" @click="save"  
                  :disabled="!promotionStore.gotChange"
                  min-width="110">
                {{ $t('promotags.PUBLISH') }}
                </v-btn>
                <v-menu
                  offset-y
                  nudge-top="0"
                  bottom
                  left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="!promotionStore.gotChange"
                      color="secondary"
                      tile
                      class="mr-4 rounded-r-xl d-none d-sm-flex"
                      v-bind="attrs"
                      v-on="on">
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item-group color="primary">
                      <v-list-item  @click="close">
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('promotags.DISCARDCHANGE') }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item  @click="close">
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('promotags.LEAVEASDRAFT') }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      
                    </v-list-item-group>
                  </v-list>
                </v-menu>
                <v-icon @click="close" dark>mdi-close</v-icon>
              </v-toolbar>

              <v-container class="fill-height pa-0 drawerbackground" fluid>
                <v-layout class="fill-height">
                  <!-- Menú en ventana -->
                  <div v-if="false" class="flex justify-center overflow-auto drawerbackground custom-tools d-none d-md-flex" :class="isDarkTheme ? 'border-dark' : 'border-light'">
                    <post-menu-options 
                      :openToolPanel = "openToolPanel"
                      :menu = "menuOptions"
                      :currentPanel = "panelValue"
                      @selectpanel="selectPanelTool">
                    </post-menu-options>
                  </div>
                  <!-- Bandeja de opciones -->
                  <transition name="drawer-menu" mode="in-out">

                    <div v-if="false" v-show="openToolPanel" style="z-index: 1" class=" overflow-y-auto pa-0" :class="[isDarkTheme ? 'border-dark' : 'border-light', !isMobileScreenXs ? 'custom-panels  pt-16' : 'custom-mobile pt-14']" :style="mobileOptionPanel">
                        <!-- Panel de Opciones de entrada -->
                        <transition name="fade-transition" mode="out-in">
                          <post-general-options
                            class="absolute-position"
                            v-if="checkCurrentPanel('posts')"
                            @close="closePanel"
                            moduleType="tags"
                            :editedItem="editedItem">
                          </post-general-options>
                        </transition>
                        <!-- Panel de Opciones de SEO -->
                        <transition name="fade-transition" mode="out-in">
                          <post-seo-options
                            class="absolute-position"
                            v-if="checkCurrentPanel('seo')"
                            @close="closePanel"
                            :editedItem="editedItem">
                          </post-seo-options>
                        </transition>
                        <!-- Panel de Opciones de Categorías -->
                        <transition name="fade-transition" mode="out-in">
                          <post-category-options
                            class="absolute-position"
                            v-if="checkCurrentPanel('categories')"
                            @close="closePanel"
                            :editedItem="editedItem">
                          </post-category-options>
                        </transition>
                        <!-- Panel de Opciones de Etiquetas -->
                        <transition name="fade-transition" mode="out-in">
                          <post-tags-options
                            class="absolute-position"
                            v-if="checkCurrentPanel('tags')"
                            @close="closePanel"
                            :editedItem="editedItem">
                          </post-tags-options>
                        </transition>
                        <!-- Panel de Opciones de Suscripciones -->
                        <!-- <transition name="fade-transition" mode="out-in">
                          <post-subscription-options
                            class="absolute-position"
                            v-if="checkCurrentPanel('subscriptions')"
                            @close="closePanel"
                            :editedItem="editedItem">
                          </post-subscription-options>
                        </transition> -->
                      </div>
                  </transition>
                  <!-- Editor de entradas -->
                  <transition name="slide-x-reverse-transition" mode="out-in">
                    <div class="flex overflow-y-auto pt-16" style="z-index: 0">
                      <post-body-editor 
                        
                        :isMobileScreen = "isMobileScreen"
                        :editedItem="editedItem"
                        :isChange="promotionStore.gotChange"
                        @save="save"
                        @toggle="toggleChange">
                      </post-body-editor>
                    </div>
                  </transition>
                </v-layout>
              </v-container>

              <v-snackbar
                style="z-index: 20;"
                color="success"
                v-model="hasSaved"
                :timeout="2000"
                absolute
                bottom>
                {{ $t('promotags.UPDATING') }}
              </v-snackbar>

              <v-bottom-navigation
                v-if="false"
                class="d-flex d-md-none"
                background-color="primary"
                fixed
                dark
                shift
                v-model="value"
                :input-value="openToolPanel">
                <v-btn @click="selectPanelTool(item.name)" v-for="item in menuOptions" :key="item.name">
                  <span>{{ $tc(item.translate, item.position) | truncate(8) }}</span>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-btn>
              </v-bottom-navigation>

              <v-fab-transition v-if="false">
                <transition name="scale-transition">
                  <v-btn
                    @click="selectPanelTool(defaultPanel)"
                    class="d-flex d-md-none"
                    color="primary"
                    dark
                    fixed
                    bottom
                    :ripple="false"
                    right
                    fab>
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </transition>
              </v-fab-transition>
            </v-dialog>

            <!-- Alerta remover usuario -->
            <v-dialog v-model="dialogDelete" max-width="400px" persistent>
              <v-card>
                <v-card-title class="title grey darken-2 white--text">
                  <v-icon dark class="mr-2">mdi-alert</v-icon> {{ $t('ATTENTION') }}
                </v-card-title>
                <v-card-text class="mt-6 text-center subtitle-1">
                  {{ $t('promotags.DELETE', { name: editedItem.name }) }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="grey darken-1" class="text-capitalize" text @click="closeDelete">
                    {{ $t('CANCEL') }}
                  </v-btn>
                  <v-btn color="primary" class="text-capitalize" dark @click="deleteItemConfirm">
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:no-data>
          <i18n path="NODATA" tag="p">
            <template #reload>
              <a @click="reloadData" class="text-lowercase">{{
                $t("RELOAD")
              }}</a>
            </template>
          </i18n>
        </template>

        <template v-slot:item="{ item, isSelected, isMobile, select}">
          <!-- Custom table row for Desktop -->
          <tr :class="isSelected ? 'selected' : ''" v-if="!isMobile">
            <td>
              <v-checkbox 
                color="primary" 
                v-model="selected" 
                :value="item.id">
              </v-checkbox>
            </td>
            <td>
              <div class="py-4 align-left">
                <v-img 
                  v-if="item.image != null"
                  @click="editItem(item)"
                  class="rounded-lg cursor-pointer"
                  max-width="220" 
                  max-height="120" 
                  :src="require(`@/assets/${item.image}`)" 
                  :alt="item.slug">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="35"
                          indeterminate
                          width="2"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                </v-img>
                <v-card @click="editItem(item)" v-else max-width="220" min-height="120" elevation="0" :color="$vuetify.theme.dark ? 'drawernumber' : 'grey lighten-4'">
                  <v-card-text>
                    <v-card :class="$vuetify.theme.dark ? 'custom-card2' : 'custom-card'"  height="100" outlined elevation="0">
                      <v-card-text class="text-center pt-5">
                        <v-icon size="60" :color="$vuetify.theme.dark ? 'drawerbackground' : 'drawernumber'">
                          mdi-image
                        </v-icon>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>
              </div>
            </td>
            <td style="min-width: 150px">{{ item.name }}</td>
            <td style="min-width: 200px">{{ item.synopsis | truncate(70) }}</td>
            <td>{{ fortmatDate(item.published) }}</td>
            <td>{{ item.autor.name.first }} {{ item.autor.name.last }}</td>
            <td>
              <v-btn
                class="mr-2"
                @click="editItem(item)"
                icon>
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-menu
                offset-x
                nudge-top="74"
                bottom
                left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item-group color="primary">
                    <v-list-item to="/">
                      <v-list-item-icon class="mr-4">
                        <v-icon>mdi-content-copy</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('promotags.DUPLICATENTRY') }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item to="/">
                      <v-list-item-icon class="mr-4">
                        <v-icon>mdi-autorenew</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('promotags.BACKTODRAFT') }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="deleteItem(item)">
                      <v-list-item-icon class="mr-4">
                        <v-icon>mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('promotags.SENDTOTRASH') }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </td>
          </tr>
          <!-- Custom card for mobile -->
          <blog-card v-else
            @deleteItem="deleteItem"
            @editItem="editItem"
            :windowsSize = "windowsSize"
            :select="select"
            :isSelected="isSelected"
            :item="item">
          </blog-card>

        </template>
      </v-data-table>
    </v-container>

    <v-fab-transition>
      <v-btn
        @click="dialog=true"
        class="d-flex d-md-none mb-6"
        color="primary"
        dark
        fixed
        bottom
        right
        fab>
        <v-icon>mdi-note-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import utils from '@/mixins/globalUtils.js'
import PostBodyEditor from '@/components/PostBodyEditor.vue'
import PostCategoryOptions from '@/components/PostCategoryOptions.vue'
import PostTagsOptions from '@/components/PostTagsOptions.vue'
// import PostSubscriptionOptions from '@/components/PostSubscriptionOptions.vue'
import PostMenuOptions from '@/components/PostMenuOptions.vue'
import { usePromotionStore } from '@/stores/promotionStore'

export default {
  name: "tags",
  props: {
    windowWidth: Number,
    saveDataAll: Function,
  },
  components: {
    blogCard: () => import(/* webpackChunkName: "blogcard" */ '@/components/BlogCard.vue'),
    PostBodyEditor,
    PostGeneralOptions: () => import(/* webpackChunkName: "general" */ '@/components/PostGeneralOptions.vue'),
    PostSeoOptions: () => import(/* webpackChunkName: "seoptions" */ '@/components/PostSeoOptions.vue'),
    PostMenuOptions,
    PostCategoryOptions,
    PostTagsOptions,
    // PostSubscriptionOptions
  },
  data() {
    return {
      right: null,
      openToolPanel: false,
      hasSaved: false,
      search: "",
      singleSelect: false,
      selected: [],
      state: "idle",
      promotionStore: usePromotionStore(),
      blog: usePromotionStore().promotion,
      dialog: false,
      dialogDelete: false,
      isLoading: false,
      value: '',
      initialData: {},
      editedIndex: -1,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
      editedItem: {
        autor: {
          id: 1,
          name: {
            first: '',
            last: '',
          },
        },
        name: '',
        image: null,
        id: null,
        published: new Date().toISOString().substr(0, 10),
        marked: false,
        synopsis: '',
        comments: false,
        relativeEntries:[],
        tags: [],
        catgories: [],
        airports:[],
        seo: {
          title: '',
          description: '',
          searchEngine: true,
        },
        social: {
          image: null,
          title: "",
          description: "",
        },
        metaTags: [
          {
            property: "og:site_name",
            value: "Air Viva",
            include: true
          },
          {
            property: "og:type",
            value: "article",
            include: true
          },
          {
            property: "canonical",
            value: "",
            include: true
          }
        ]
      },
      defaultItem: {
        autor: {
          id: 1,
          name: {
            first: '',
            last: '',
          },
        },
        name: '',
        image: null,
        id: usePromotionStore().totalCount + 1,
        published: new Date().toISOString().substr(0, 10),
        marked: false,
        synopsis: '',
        comments: false,
        relativeEntries:[],
        tags: [],
        catgories: [],
        airports:[],
        seo: {
          title: '',
          description: '',
          searchEngine: true,
        },
        social: {
          image: null,
          title: "",
          description: "",
        },
        metaTags: [
          {
            property: "og:site_name",
            value: "Air Viva",
            include: true
          },
          {
            property: "og:type",
            value: "article",
            include: true
          },
          {
            property: "canonical",
            value: "",
            include: true
          }
        ]
      },
      menuOptions: [
        {
          name: "posts",
          translate: "promotags.OPTIONS",
          position: 1,
          icon: 'mdi-cog'
        },
        {
          name: "seo",
          translate: "promotags.SEO",
          position: 1,
          icon: 'mdi-search-web'
        },
        {
          name: "categories",
          translate: "promotags.CATEGORY",
          position: 2,
          icon: 'mdi-cards'
        },
        {
          name: "tags",
          translate: "promotags.TAGS",
          position: 2,
          icon: 'mdi-tag-multiple'
        },
        // {
        //   name: "subscriptions",
        //   translate: "SUBSCRIPTION",
        //   position: 2,
        //   icon: 'mdi-currency-usd'
        // }
      ],
      currentPanel: '',
      defaultPanel: 'posts'
    };
  },
  computed: {
    getPromotionList () {
      return usePromotionStore().promotion;
    },
    formTitle () {
      return this.editedIndex === -1 ? 'promotags.NEW' : 'promotags.EDIT'
    },
    formIcon () {
      return this.editedIndex === -1 ? 'mdi-note-plus' : 'mdi-file-edit'
    },
    headers() {
      return [
        { text: this.$t('COVERIMAGE'), value: 'avatar', sortable: false, align:'start' },
        { text: this.$t('TITLE'), value: 'name' },
        { text: this.$tc('SYNOPSIS', 1), value: 'description' },
        { text: this.$t('PUBLISHED'), value: 'birthday' },
        { text: this.$tc('promotags.AUTOR', 1), value: 'autor' },
        { text: this.$tc('ACTION', 2), value: 'actions', sortable: false },
      ]
    },
    panelValue () {
      if(this.currentPanel) 
        return this.currentPanel 
      else 
        return this.defaultPanel
    },
    isSelected () {
      return this.selected.length > 0;
    },
    isDarkTheme(){
      return this.$vuetify.theme.dark;
    },
    windowsSize () {
      return (this.windowWidth -28)
    },
    isMobileScreen () {
      return this.windowWidth < 600
    },
    isMobileScreenXs () {
      return this.windowWidth < 460
    },
    mobileOptionPanel() {
      return {
        '--widthscreen': `${this.windowWidth}px`,
      };
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  methods: {
    reloadData () {
      console.log('reload Data')
    },
    saveDate (date) {
      this.$refs.menu.save(date)
    },

    editItem (item) {
      this.initialData = Object.assign({}, item);
      this.editedIndex = this.blog.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.blog.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.blog.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.openToolPanel = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      this.isLoading = true;
      if (this.editedIndex > -1) {
        this.promotionStore.editPromotion(this.blog[this.editedIndex]['id'], this.editedItem)
      } else {
        this.promotionStore.addPromotion(this.editedItem)
      }
      this.hasSaved = true
      this.saveDataAll();
      this.$nextTick(() => {
        this.initialData = Object.assign({}, this.editedItem);
        this.promotionStore.toggleChangeData(false);
        this.isLoading = false;
      })
      this.close()
    },

    toggleChange () {
      if (Object.entries(this.editedItem).toString() === Object.entries(this.initialData).toString()) {
        this.promotionStore.toggleChangeData(false);
      } else {
        this.promotionStore.toggleChangeData(true);
      }
    },

    fortmatDate (date) {
      return utils.fortmatDate(date, this.$i18n.locale)
    },

    closePanel() {
      this.openToolPanel = false
    },
    
    selectPanelTool (label = 'posts') {
      if (this.currentPanel === label) {
        this.openToolPanel = !this.openToolPanel
      } else {
        this.currentPanel = label
        if (!this.openToolPanel) {
          this.openToolPanel = !this.openToolPanel
        }
      }
    },

    checkCurrentPanel (value) {
      if(this.currentPanel != '')
        return value === this.currentPanel
      else 
        return value === this.defaultPanel
    }

  },
}
</script>

<style scoped>
.cursor-pointer:hover  {
  cursor: pointer;
}
.drawer-menu-enter-active {
  animation: animateDrawer .2s ease-out .0s;
}
.drawer-menu-leave-active {
  animation: animateDrawer .2s ease-out .0s reverse;
}
@keyframes animateDrawer {
  0% {
    opacity: 0;
    transform: translateX(-280px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.custom-tools {
  z-index: 10;
  min-width: 110px;
  max-width: 110px;
}
.custom-panels {
  position: relative;
  max-width: 370px;
  min-width: 370px;
}
.custom-mobile {
  position: relative;
  min-width: var(--widthscreen);
}
.custom-toolbar{
  z-index: 20;
}
@media (min-width: 460px) {
  .border-light {
    border-right: #dddddd solid 1px !important;
  }
  .border-dark {
    border-right: #333333 solid 1px !important;
  }
}
.absolute-position{
  position: absolute;
}
</style>
